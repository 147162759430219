import clsx from 'clsx';
import * as React from 'react';
import { ReactElement } from 'react';

export function Badge({ variant }: { variant: string }): ReactElement {
  let spanClass = 'bg-lapis-800 text-lapis-100';
  let svgClass = 'text-lapis-100';

  if (variant === 'Lapis Light') {
    spanClass = 'bg-amethyst-800 text-amethyst-100';
    svgClass = 'text-amethyst-100';
  }

  if (variant === 'Lapis Dark') {
    spanClass = 'bg-ruby-800 text-ruby-100';
    svgClass = 'text-ruby-100';
  }

  return (
    <span
      className={clsx(
        'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium',
        spanClass
      )}
    >
      <svg
        className={clsx('-ml-0.5 mr-1.5 h-2 w-2', svgClass)}
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
      {variant}
    </span>
  );
}
