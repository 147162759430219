import { Link } from 'gatsby';
import * as React from 'react';
import { ReactNode } from 'react';
import logo from '../images/logo.svg';

const listItems = [
  { label: 'Lapis', backgroundColor: 'bg-lapis-500' },
  { label: 'Amethyst', backgroundColor: 'bg-amethyst-500' },
  { label: 'Ruby', backgroundColor: 'bg-ruby-500' },
  { label: 'Amber', backgroundColor: 'bg-amber-500' },
  { label: 'Peridot', backgroundColor: 'bg-peridot-500' },
  { label: 'Aquamarine', backgroundColor: 'bg-aquamarine-500' },
  { label: 'Quartz', backgroundColor: 'bg-grey-50' },
];

function AboutSection(props: { className?: string }) {
  const ListItem = ({
    label,
    backgroundColor,
  }: {
    label: string;
    backgroundColor: string;
  }) => {
    return (
      <li className="flex items-center">
        <div className={`w-2 h-2 ${backgroundColor} rounded-full mr-2`} />
        {label}
      </li>
    );
  };

  return (
    <section {...props}>
      <h2 className="flex items-center text-sm leading-7 text-grey-50">
        About
      </h2>
      <div className="mt-2 text-base leading-7 font-light text-grey-200">
        Lapis theme has a dark blue base with the following color palette:
        <ul>
          {listItems.map((listItem) => (
            <ListItem key={listItem.label} {...listItem} />
          ))}
        </ul>
      </div>
    </section>
  );
}

export function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <header className="bg-grey-1000 lg:fixed lg:inset-y-0 lg:left-0 lg:flex lg:w-112 lg:items-start lg:overflow-y-auto xl:w-120">
        <div className="hidden lg:sticky lg:top-0 lg:flex lg:w-16 lg:flex-none lg:items-center lg:whitespace-nowrap lg:py-12 lg:text-sm lg:leading-7 lg:[writing-mode:vertical-rl]">
          <span className="text-grey-50 font-light">
            &copy; {new Date().getFullYear()} Alex Barnett. All Rights Reserved.
          </span>
        </div>
        <div className="relative z-10 mx-auto px-4 pb-4 pt-10 sm:px-6 md:max-w-2xl md:px-4 lg:min-h-full lg:flex-auto lg:border-x lg:border-grey-700 lg:py-12 lg:px-8 xl:px-12">
          <Link
            aria-label="Homepage"
            to="/"
            className="relative mx-auto block w-48 overflow-hidden rounded-full bg-grey-800 sm:w-64 sm:rounded-full md:w-64 lg:w-80 lg:rounded-full"
          >
            <img
              className="w-full lg:w-80 md:w-64 w-48"
              src={logo}
              alt="Lapis Logo"
            />
            <div className="absolute inset-0 rounded-full ring-1 ring-inset ring-grey-700 sm:rounded-full lg:rounded-full" />
          </Link>
          <div className="mt-10 text-center lg:mt-12 lg:text-left">
            <p className="text-xl text-grey-50">
              <Link to="/">Lapis Theme</Link>
            </p>
            <p className="mt-3 text-lg font-light leading-8 text-grey-200">
              A dark blue inspired theme with a complimentary color palette.
            </p>
          </div>
          <AboutSection className="mt-12 hidden lg:block" />
        </div>
      </header>
      <main className="border-t border-grey-800 lg:relative lg:pb-28 lg:ml-112 lg:border-t-0 lg:min-h-screen xl:ml-120 bg-grey-900">
        <div className="relative">{children}</div>
      </main>
      <footer className="border-t border-grey-800 bg-grey-1000 py-10 pb-40 sm:py-16 sm:pb-32 lg:hidden">
        <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4">
          <AboutSection />
          <h2 className="mt-8 flex items-center text-sm font-light leading-7 text-grey-50">
            &copy; {new Date().getFullYear()} Alex Barnett. All Rights Reserved.
          </h2>
        </div>
      </footer>
    </>
  );
}
