import type { HeadFC } from 'gatsby';
import * as React from 'react';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Port } from '../components/Port';
import { ports } from '../util/ports';

const IndexPage = () => {
  return (
    <Layout>
      <div className="pt-16 pb-12 sm:pb-4 lg:pt-12">
        <Container>
          <h1 className="text-2xl leading-7 text-grey-50">Ports</h1>
        </Container>
        <div className="divide-y divide-grey-800 sm:mt-4 lg:mt-8 lg:border-t lg:border-grey-800">
          {ports.map((port) => (
            <Port key={port.title} port={port} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Lapis Theme</title>;
