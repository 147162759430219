import * as React from 'react';
import { ReactElement } from 'react';
import { Port as PortType } from '../types';
import { Badge } from './Badge';
import { Container } from './Container';

function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
      />
    </svg>
  );
}

export function Port({ port }: { port: PortType }): ReactElement {
  return (
    <article
      aria-labelledby={`port-${port.title}-title`}
      className="py-10 sm:py-12"
    >
      <Container>
        <div className="flex flex-col items-start">
          <h2
            id={`port-${port.title}-title`}
            className="mt-2 text-lg text-grey-50"
          >
            {port.title}
          </h2>
          <p className="order-first text-sm font-light leading-7 text-grey-300">
            {port.version}
          </p>
          <div className="mt-1 flex flex-wrap gap-2">
            {port.baseVersions.map((variant) => (
              <Badge key={variant} variant={variant} />
            ))}
          </div>
          <div className="mt-4 flex items-center gap-4">
            <div className="flex items-center text-sm leading-6 text-lapis-500 hover:text-lapis-300 active:text-lapis-50 cursor-pointer">
              <LinkIcon />
              <a className="ml-2" href={port.github} target="_blank">
                GitHub
              </a>
            </div>
          </div>
        </div>
      </Container>
    </article>
  );
}
