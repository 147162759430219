import { Port } from '../types';

const generateVariant = (variant: string) => {
  switch (variant) {
    case 'Lapis':
      return {
        label: 'Lapis',
        bg100: 'bg-lapis-100',
        txt400: 'text-lapis-400',
        txt800: 'text-lapis-800',
      };
    case 'Amethyst':
      return {
        label: 'Amethyst',
        bg100: 'bg-amethyst-100',
        txt400: 'text-amethyst-400',
        txt800: 'text-amethyst-800',
      };
    case 'Ruby':
      return {
        label: 'Ruby',
        bg100: 'bg-ruby-100',
        txt400: 'text-ruby-400',
        txt800: 'text-ruby-800',
      };
    case 'Amber':
      return {
        label: 'Amber',
        bg100: 'bg-amber-100',
        txt400: 'text-amber-400',
        txt800: 'text-amber-800',
      };
    case 'Peridot':
      return {
        label: 'Peridot',
        bg100: 'bg-peridot-100',
        txt400: 'text-peridot-400',
        txt800: 'text-peridot-800',
      };
    case 'Aquamarine':
      return {
        label: 'Aquamarine',
        bg100: 'bg-aquamarine-100',
        txt400: 'text-aquamarine-400',
        txt800: 'text-aquamarine-800',
      };
    case 'Quartz':
      return {
        label: 'Quartz',
        bg100: 'bg-grey-100',
        txt400: 'text-grey-400',
        txt800: 'text-grey-800',
      };
    default:
      return {
        label: 'Quartz',
        bg100: 'bg-grey-100',
        txt400: 'text-grey-400',
        txt800: 'text-grey-800',
      };
  }
};

export const ports: Array<Port> = [
  {
    title: 'Visual Studio Code',
    version: 'v1.6.21',
    baseVersions: ['Lapis', 'Lapis Light', 'Lapis Dark'],
    accentVariants: [
      'Lapis',
      'Amethyst',
      'Ruby',
      'Amber',
      'Peridot',
      'Aquamarine',
      'Quartz',
    ].map((variant) => generateVariant(variant)),
    github: 'https://github.com/aslbarnett/lapis-vscode',
  },
  {
    title: 'JetBrains IDEs',
    version: 'v1.6.3',
    baseVersions: ['Lapis'],
    accentVariants: ['Lapis'].map((variant) => generateVariant(variant)),
    github: 'https://github.com/aslbarnett/lapis-jetbrains',
  },
  {
    title: 'Slack',
    version: 'v1.1.1',
    baseVersions: ['Lapis', 'Lapis Light', 'Lapis Dark'],
    accentVariants: [
      'Lapis',
      'Amethyst',
      'Ruby',
      'Amber',
      'Peridot',
      'Aquamarine',
      'Quartz',
    ].map((variant) => generateVariant(variant)),
    github: 'https://github.com/aslbarnett/lapis-theme-slack',
  },
  {
    title: 'Terminal.App',
    version: 'v1.3.0',
    baseVersions: ['Lapis'],
    accentVariants: [
      'Lapis',
      'Amethyst',
      'Ruby',
      'Amber',
      'Peridot',
      'Aquamarine',
      'Quartz',
    ].map((variant) => generateVariant(variant)),
    github: 'https://github.com/aslbarnett/lapis-theme-terminal-app',
  },
  {
    title: 'Google Chrome',
    version: 'v1.0',
    baseVersions: ['Lapis'],
    accentVariants: ['Lapis'].map((variant) => generateVariant(variant)),
    github: 'https://github.com/aslbarnett/lapis-theme-google-chrome',
  },
];
